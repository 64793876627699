import * as R from 'ramda'
import {bool, shape, string} from 'prop-types'
import {graphql} from 'gatsby'
import React from 'react'
import {Helmet} from 'react-helmet'

import {slugTransformer} from 'helpers/utils'
import {pageContextPropTypes} from 'helpers/propTypes'
import Hero from 'components/UI/Hero'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'

import SEO from '../components/seo'

const MaintenancePage = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const {
    callToAction: pageCta,
    callToActionLinkedPage,
    description: pageDescription,
    image,
    title: pageTitle,
  } = R.pathOr(null, ['contentfulMaintenancePage'], data)

  const contactUsCtaSlug = slugTransformer(
    R.pathOr('', ['slug'], callToActionLinkedPage),
  )

  const {logo} = R.pathOr(null, ['contentfulHeader'], data)

  const logoDivStyles = {
    margin: 'auto',
    textAlign: 'center',
    width: 200,
  }

  const pageMetaData = {
    metaTitle: 'Maintenance Page',
    metaDescription:
      'Our website is currently undergoing some changes! Stay tuned for more very soon.',
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasPaddingTop={false}>
        <div style={logoDivStyles}>
          <Media data={logo} />
        </div>
        <Hero
          title={pageTitle}
          media={image}
          description={pageDescription}
          ctaTitle={pageCta}
          ctaLink={`../${contactUsCtaSlug}`}
          ctaColor="secondary"
          isAlignCenter
          hasSmallVerticalMargin
        />
      </Section>
    </>
  )
}

MaintenancePage.propTypes = {
  data: shape({
    contentfulMaintenancePage: shape({
      title: string,
      description: string,
      image: shape({
        file: shape({url: string, fileName: string, contentType: string}),
      }),
      callToAction: string,
      callToActionLinkedPage: shape({slug: string}),
    }),
    contentfulCountry: shape({
      countryCode: string,
      navLocale: string,
      urlPrefix: string,
      isMultilang: bool,
      technicalName: string,
    }),
    contentfulHeader: shape({
      logo: shape({file: shape({url: string, contentType: string})}),
    }),
  }),
  pageContext: shape({
    contentId: string,
    lastMod: string,
    nodeLocale: string,
    prefix: string,
    slug: string,
    technicalName: string,
  }),
}

MaintenancePage.defaultProps = {
  data: shape({
    contentfulCountry: {},
    contentfulHeader: {},
    contentfulMaintenancePage: {},
  }),
  pageContext: pageContextPropTypes.isRequired,
}

export default MaintenancePage

export const pageQuery = graphql`
  query templateMaintenancePage(
    $nodeLocale: String
    $technicalName: String
    $entityRegEx: String
  ) {
    contentfulMaintenancePage(
      slug: {regex: $entityRegEx}
      node_locale: {eq: $nodeLocale}
    ) {
      title
      description
      image {
        file {
          url
          fileName
          contentType
        }
      }
      callToAction
      callToActionLinkedPage {
        slug
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulHeader(name: {regex: $entityRegEx}, node_locale: {eq: "en-US"}) {
      logo {
        file {
          url
          contentType
        }
      }
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
  }
`
